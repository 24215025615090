function fetchMap() {
  if (!window.auth0) {
    return setTimeout(fetchMap, 1000);
  }
  window.auth0.getTokenSilently().then((token) => {
    if (!token) {
      return;
    }

    const url = window.origin.includes("localhost")
      ? "http://localhost:1235/entur-floorplan/europe-west1/kart/kart"
      : "https://europe-west1-entur-floorplan.cloudfunctions.net/kart/kart";

    return fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.text())
      .then((map) => {
        document.getElementById("main").innerHTML = map;
      })
      .then(() => {
        const hashValue = decodeURIComponent(
          (window.location.hash || "").replace("#", "")
        );
        document
          .getElementById("search")
          .addEventListener("input", function (e) {
            window.search(e.currentTarget.value);
          });
        document.getElementById("search").value = hashValue;
        window.search(hashValue);
      });
  });
}

fetchMap();
